import React from 'react';

import '../../styles/about.css';
import aboutImg from "../../images/about-us.jpg";



const chooseData = [
    {
        icon:'ri-wifi-line',
        title:'First working process',
        desc:"If you describe the UK tech culture in one word, it would be quality as we are serious about being the best in our industry."
    },
    {
        icon:'ri-team-line',
        title:'Dedicated team',
        desc:"With a 'can-do' attitude, our expert team works towards achieving client's goals and providing them with value-added services."
    },
    {
        icon:'ri-customer-service-line',
        title:'24/7 Hours support',
        desc:"Our friendly support team is available to help you 24 hours a day, seven days a week. We are able to assist you contact us. "
    }
]

const About = () => {
  return (
    <section id="about">
        <div className="container">
            <div className="about__wrapper">
                <div className="about__content">
                    <h6 className="subtitle">Why choose us</h6>
                    <h2>We Deliver Tech-Driven Business Solutions</h2>
                    <h2 className='highlight'>financial challenges</h2>
                    <p className="description about__content-desc">Uk Tech is a client-focused company with focus on quality services. We can lead or follow. Our main goal is to maintain the highest quality standards and the best practices in today's IT market.</p>
<div className='choose__item-wrapper'>
                        {
                            chooseData.map((item,index)=>(
                                <div className="choose__us-item" key={index}>
                                    <span className="choose__us-icon">
                                    <i className={item.icon}>
                                    </i></span>
                                <div>
                                    <h4 className="choose__us-title">{item.title}</h4>
                                    <p className="description">{item.desc}
                                    </p>
                                </div>                            
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="about__img">
                    <img src={aboutImg} alt="" />
                </div>

            </div>

        </div>
    </section>
  )
}

export default About