import React from 'react'
import '../../styles/hero.css';


import heroDarkImg from '../../images/hero-img.png';
import lightImg from '../../images/light-hero-bg.jpg';

function Hero({theme}) {
  return (
    <section className="hero__section" id='home'>
        <div className="container">
            <div className="hero__wrapper">
                <div className="hero__content">
                    <div>
                        <h2>Experts in provide your</h2>
                        <h2>Tech Services</h2>
                        <h2 className="highlight">Solution Beyond Tech</h2>  
                    </div>
                    <p className="description">Computer and Security system
                    CCTV Cameras
                    Software & Hardware
                    Networking
                    Home & Office Services
                    Domain & Hosting
                    Web Desiging
                    </p>
                    <div className="hero__btns">
                        <a href="#about"><button className="primary__btn" >Get started Now</button></a>
                        <a href="#about"><button className="secondary__btn">Discover More </button></a>
                    </div>
                </div>
                <div className="hero__img">
                    <img src={theme === 'light-theme' ? lightImg : heroDarkImg} alt="hero-img" />
                </div>
            </div>
        </div>
    </section>
  )
}

export default Hero