import React from 'react'
import '../Footer/footer.css'

import logoImg2 from "../../images/2.png";

const quickLink01 = [
    {
        path:'#about',
        display:'About'
    },
    
    {
        path:'#portfolio',
        display:'Portfolio'
    },
    {
        path:'#about',
        display:'Analytics'
    }
]
const quickLink02 = [
    {
        path:'#service' ,
        display:'Service'
    },
    {
        path:'#project',
        display:'Project'
    },
    {
        path:'#about',
        display:'Guides'
    }
];





const Footer = () => {

    const year = new Date().getFullYear();
  return (
    <footer className="footer">
        <div className="container">
        <div className="footer__wrapper">
            <div className="footer__logo">
                <a href="#home" >
                    <img src={logoImg2} alt="UK Tech"/> 
                  
                </a>  
                <p className="small__text description">Uk Tech is a client-focused company with focus on quality services. We can lead or follow. Our main goal is to maintain the highest quality standards and the best practices in today's IT market.</p>
            </div>  
            <div className="footer__quick-links">
                <div className="quick__links-title">Support</div>
                <ul className="quick__links">
                    {
                        quickLink01.map((item,index)=>(
                            <li className='quick__links-item' key={index}><a href={item.path}>{item.display}</a></li>
                        ))
                    }
                </ul>
            </div>
                        <div className="footer__quick-links">
                <div className="quick__links-title">Company</div>
                <ul className="quick__links">
                    {
                        quickLink02.map((item,index)=>(
                            <li className='quick__links-item' key={index}><a href={item.path}>{item.display}</a></li>
                        ))
                    }
                </ul>
            </div>
                        <div className="footer__quick-links">
                <div className="quick__links-title">Contacts</div>
                <div className="quick__links">
                    <li className='quick__links-item' ><a href="#footer">1st Floor, Uni Center, 106/6 I.I <br/> Chundrigar Rd, Seari Quarters,<br/> Karachi, Karachi City</a></li>
                    <li className='quick__links-item' ><a href="#footer">+923332897323<br/>Info@uktech.pk</a></li>
                    <ul class="wrapper">
  <li class="icon facebook">
    <span class="tooltip">Facebook</span>
    <span><a href="https://www.facebook.com/uktechPk" target="_blank"><i class="ri-facebook-fill" target="_blank"></i></a></span>
  </li>
  <li class="icon twitter">
    <span class="tooltip">Whatsapp</span>
    <span><a href="https://api.whatsapp.com/send/?phone=923332897323" target="_blank"><i class="ri-whatsapp-line" target="_blank"></i></a></span>
  </li>
  <li class="icon instagram">
    <span class="tooltip">Instagram</span>
    <span><a href="#" target="_blank"><i class="ri-instagram-line" ></i></a></span>
  </li>

</ul>
                </div>
            </div>
            
        </div>
            <p className="copyright">Copyright {year}, developed by Shahmeer. All right reserved. </p>
        </div>
    </footer>
  )
}

export default Footer