import React, {useState, useEffect} from "react";

import './App.css';
import "react-whatsapp-chat-widget/index.css";

import Header from './components/Header/Header';
import Hero from './components/UI/Hero';
import Counter from './components/UI/Counter';
import Service from './components/UI/Service';
import About from './components/UI/About';
import Portfolio from './components/UI/Portfolio';
import Testimonial from './components/UI/Testimonial';
import Newsletter from './components/UI/Newsletter';
import Footer from './components/Footer/Footer';
import WhatsAppWidget from "react-whatsapp-chat-widget";

function App() {

  const [theme, setTheme] = useState('');

  const toggleTheme = () => {
    theme === '' ? setTheme('light-theme') : setTheme('')
  }

  useEffect (()=>{
    document.body.className = theme
  },[theme])

  return (
    <>
    <Header theme={theme} toggleTheme={toggleTheme}/>
    <Hero theme={theme}/>
    <Counter/>
    <Service/>
    <About/>
    <Portfolio/>
    <Testimonial/>
    
    <Newsletter/>
    <Footer/>
    <WhatsAppWidget
			phoneNo="923332897323"
			position="right"
			widgetWidth="300px"
			widgetWidthMobile="260px"
			autoOpen={true}
			autoOpenTimer={5000}
			messageBox={true}
			messageBoxTxt="Hello UK Tech!"
			iconSize="50"
			iconColor="var(--body-bg)"
			iconBgColor="var(--primary-color)"
			headerIconColor="var(--primary-color)"
			headerTxtColor="black"
			headerBgColor="var(--primary-color)"
			headerTitle="Support"
			headerCaption="We'll reply ASAP"
			bodyBgColor="#bbb"
			chatPersonName="Support"
			chatMessage={<>Hi there 👋 <br /><br /> How can I help you?</>}
			footerBgColor="#999"
			btnBgColor="var(--btn-secondary-bg)"
			btnTxtColor="white"
			btnTxt="Start Chat"
		/>
    </>
  );
}

export default App;
