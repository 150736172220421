import React from 'react';

import '../../styles/portfolio.css';

import videoImg from '../../images/video.png'
import articleImg from '../../images/article.png'
import caseStudy from '../../images/case-study.png'

const portfolioData = [
  {
    imgUrl: videoImg,
    title: 'Video',
    desc:'To Know about work. Watch some videos ....',
    linkUrl:'#'
  },
  {
    imgUrl: articleImg,
    title: 'Articles',
    desc:'Do you want to improve the way your brand interacts with customer? Lets talk.....',
    linkUrl:'#'
  },
  {
    imgUrl: caseStudy ,
    title: 'Case study',
    desc:'Boost you conversation rae with us. ....',
    linkUrl:'#'
  },
  
]

const Portfolio = () => {
  return (
    <section className='blog' id='portfolio'>
    <div className='container' >
      <div className="blog__top-content">
        <h6 className="subtitle">Our Proud Work</h6>
        <h2>Projects we have
          <span className="highlight"> Nicely Done</span>
        </h2>
      </div>
      <div className="blog__wrapper">
        {
          portfolioData.map((item,index)=>(
        <div className="blog__item" key={index}>
          <h3>{item.title}</h3>
          <div className="blog__img">
            <img src={item.imgUrl} alt="" />
          </div>
          <p className="description blog__desc">
            {item.desc}
          </p>

          <div>
            <a href={item.linkUrl} className="learn__more">
              <i className="ri-arrow-right-line"></i>
            </a>
          </div>
        </div>
          ))
        }
      </div>
    </div>
    </section>
  )
}

export default Portfolio