import React from 'react'
import '../../styles/service.css';

const serviceData1 = [
    {
        icon:'ri-rocket-line',
        title:'Digital Marketing',
        desc:'It’s more than tweaking the digits and scaling the numbers. By providing full-service digital marketing service. '
    },
    {
        icon:'ri-code-s-slash-line',
        title:'Web Designer',
        desc:'UI and UX go hand-in-hand, And with us by your side, you can make online presence not only felt but which compel viewers action.'
    },
    {
        icon:'ri-camera-switch-line',
        title:'CCTV Cameras',
        desc:'We provide CCTV cameras professionally and maintain everything. CCTV Surveillance System for clients based markets.'
    },
    {
        icon:'ri-hard-drive-2-line',
        title:'Hardware Provider',
        desc:'We also provide computing and CCTV devices hardware component and different electronic items'
    },
    
    
]
const serviceData2 = [
    {
        icon:'ri-bug-line',
        title:'Computer & Security',
        desc:'5 years, to empower and protect the enterprise with continuous security assurance for people, systems, and information.'
    },
    {
        icon:'ri-server-line',
        title:'Domain & Hosting',
        desc:'We provide fast and affordable website hosting and domain services. Get your domain & hosting in minutes! '
    },
    {
        icon:'ri-hotel-line',
        title:'Home and Office',
        desc:'We provide home and office services and janitorial service to our valued clients. We have team of experts.'
    },
    {
        icon:'ri-swap-line',
        title:'Networking',
        desc:'UK Tech has strong footprints in world market due to our creative ideas, and professionalism, which makes us good.'
    },
    
    
]

const Service = () => {
  return (
    <section id='service'>
        <div className="container">
            <div className="services__top-content">
                <h6 className="subtitle">Our Service</h6>
                <h2>Save time managing your business with</h2>
                <h2 className='highlight'>Our best Service</h2>
            </div>
            <div className="service__item-wrapper">
                {
                    serviceData1.map((item, index)=>(
                     <div className="services__item" key={index}>
                    <span className="service__icon"><i class={item.icon}></i></span>
                    <h3 className="service__title">{item.title}</h3>
                    <p className="description">{item.desc}</p>
                    </div>
                    ))
                }
                </div>
                <div className="service__item-wrapper">
                    {
                    serviceData2.map((item, index)=>(
                     <div className="services__item" key={index}>
                    <span className="service__icon"><i class={item.icon}></i></span>
                    <h3 className="service__title">{item.title}</h3>
                    <p className="description">{item.desc}</p>
                    </div>
                    ))
                    }
                </div>
             
        </div>
    </section>
  )
}

export default Service