import React, {useRef, useEffect} from 'react'
import "./header.css";

import logoImg1 from "../../images/uk-tech-logo.png";
import logoImg2 from "../../images/2.png";

const nav__links = [
    {
        path:'#home',
        display: 'Home'
    },
    {
        path:'#about',
        display: 'About'
    },
    {
        path:'#service',
        display: 'Service'
    },
    {
        path:'#projects',
        display: 'Project'
    },
    {
        path:'#portfolio',
        display: 'Portfolio'
    }
]


function Header({theme, toggleTheme}) {

    const headerRef = useRef(null);

    const menuRef = useRef(null);


    const headerFunc = () => {
        if(document.body.scrollTop > 80 || document.documentElement.scrollTop > 80){
            headerRef.current.classList.add('header__shrink')
        } else {
            headerRef.current.classList.remove('header__shrink')
        }
    }
    useEffect(()=>{
        window.addEventListener('scroll', headerFunc);
        return ()=> window.removeEventListener('scroll', headerFunc)
    }, [])


    const handleClick = e => {
        e.preventDefault()

        const targetAttr = e.target.getAttribute('href')
        const location = document.querySelector(targetAttr).offsetTop;
        window.scrollTo({
            left:0,
            top: location - 80,

        });
    }

    const toggleMenu = () => menuRef.current.classList.toggle('menu__active')

  return (
    <header className="header" ref={headerRef}>
        <div className="container">
            <div className="nav__wrapper">
                <div className="logo">
                    
                <a href='#home'  >
                    {
                    theme ==='light-theme'  ?<img src={logoImg1} alt="UK Tech"/>: <img src={logoImg2} alt="UK Tech"/> 
                    }
                </a>  
                </div>
                {/* ============= navigation ============= */}
                <div className="navigation" ref={menuRef} onClick={toggleMenu}>
                    <ul className="menu">{
                        nav__links.map((item, index)=>(
                            <li className="menu_item" key={index}>
                                <a href={item.path} onClick= {handleClick}className="menu__link">{item.display}</a>
                            </li>
                        ))}
                    </ul>
                </div>
                {/* ================ light mode =============== */}
                <div className="light__mode">
                    <span onClick={toggleTheme}>
                        {
                        theme ==='light-theme'  ?<span><i className="ri-moon-line"></i>Dark</span>: <span><i class='ri-sun-line'></i> Light</span> 
                        }
                        </span>
                </div>
                <span className="mobile__menu" onClick={toggleMenu}>
                    <i className="ri-menu-line"></i>
                </span>
            </div>
        </div>
    </header>
    )
}

export default Header