import React from 'react'

import '../../styles/newsletter.css'

const Newsletter = () => {
  return (
    <div>
        <div>
        <iframe 
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14481.629074481185!2d67.0072003!3d24.8499361!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa859c8dc723d0429!2sUK%20TECH!5e0!3m2!1sen!2s!4v1661104662618!5m2!1sen!2s" 
        width="100%" 
        height="300" 
        style={{border:0}}
        allowFullScreen="" 
        loading="lazy" 
        referrerpolicy="no-referrer-when-downgrade"/>
        </div>
    <section className="newsletter">
        
        <div className="container">
            <div className="newsletter__wrapper">
                <div className="newsletter__content">
                    <h6 className="subtitle">Let's work</h6>
                    <h2>Explore the <span className="highlight">hidden</span> ideas and Subscribe!</h2>
                </div>
                <div className="newsletter__form">
                    <input type="email" name="" placeholder='Email' />
                    <button className='secondary__btn subscribe__btn'>Subscribe Now</button>
                </div>
            </div>
        </div>
    </section>
    </div>

  )
}

export default Newsletter