import React from 'react';
import '../../styles/testimonial.css';

import Slider from "react-slick";

import ava01 from '../../images/ava-1.jpg'
import ava02 from '../../images/ava-2.jpg'
import ava03 from '../../images/ava-3.jpg'



const settings = {
    dots: false,
    Infinite:true,
    speed:1000,
    autoplay: true,
    autoplaySpeed: 3000,  
    slidesToShow: 1,
    slidesToScroll:1,
    swipeToSlide: true
}

const Testimonial = () => {
  return (
    <section>
        <div className="container">
            <div className="slider__content-top">
                <h6 className="subtitle">Testimonials</h6>
                <h2>Trusted by more than{" "}<span className="highlight">500 Customer</span></h2>
            </div>
            <div className="slider__wrapper">
            <Slider {...settings}>
            <div className="slider__item">
                <p className="description">They are exclusively professional and I like the fact that they have such good strong communication.UK Tech is an esteemed organization, we suggest to expend their working domain in Oracle relevant areas. 
                                    Overall very good product and services UK Tech has worked on this project adhering to the standard
							professionalism and produced deliverables .</p>
                <div className="customer__details">
                    <div className="customer__img">
                        <img src={ava01} alt="" />
                    </div>
                    <div >
                        <h5 className="customer__name">Andrew Cooper</h5>
                        <p className="description">Ceo, Workcreation</p>
                    </div>
                </div>
            </div>
            <div className="slider__item">
                <p className="description">UK Tech completed a full scope of the project. UK Tech has worked on this project adhering to the standard
							professionalism and produced deliverables which are of prime importance to Brand to perform
							department related operations.</p>
                <div className="customer__details">
                    <div className="customer__img">
                        <img src={ava02} alt="" />
                    </div>
                    <div >
                        <h5 className="customer__name">Anney Martin</h5>
                        <p className="description">Software Developer </p>
                    </div>
                </div>
            </div>
            <div className="slider__item">
                <p className="description">
                                    UK Tech is an esteemed organization, we suggest to expend their working domain in Oracle relevant areas. 
                                    Overall very good product and services UK Tech has worked on this project adhering to the standard
							professionalism and produced deliverables .
                                </p>
                <div className="customer__details">
                    <div className="customer__img">
                        <img src={ava03} alt="" />
                    </div>
                    <div >
                        <h5 className="customer__name">Jhon Doe</h5>
                        <p className="description">Sr, Product Designer</p>
                    </div>
                </div>
            </div>
            </Slider>
            </div>
        </div>
    </section>
  )
}

export default Testimonial